var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:".container"},[_c('Header'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"spaceFinal"}),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"banner"},[_c('div',[_c('p',[_vm._v("Development")])]),_c('h1',[_vm._v(" Davis Ind Group ")]),_c('p',[_vm._v(" Extensión de formulario para clientes "),_c('br',{staticClass:"only-mov"}),_vm._v(" potenciales "),_c('br',{staticClass:"only-pc"}),_vm._v(" en Google Ads: Búsqueda,"),_c('br',{staticClass:"only-mov"}),_vm._v(" Display, Youtube y "),_c('br',{staticClass:"only-pc"}),_vm._v("Discovery ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"parallax"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',[_c('p',[_vm._v("El reto")]),_c('h2',[_vm._v(" Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")]),_c('p',{staticClass:"only-mov"},[_vm._v(" ¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y creo que no se van a oponer, pero para el primer mes de confinamiento ya no hallábamos qué hacer con nuestras vidas. Y es por eso que me terminé haciendo adicta a las aplicaciones… y no se hagan, ustedes también. "),_c('br'),_c('br'),_vm._v(" No los culpo, de hecho se transformaron en una especie de “alivio” durante estos meses difíciles. ")])]),_c('div',[_c('picture',{staticClass:"spcelyr"},[_c('source',{attrs:{"srcset":require("../../assets/landingDesarrolloPortafolio/logoDavis.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/landingDesarrolloPortafolio/logoDavis.png"),"alt":"Logo Davis Ind Group","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"video"},[_c('iframe',{staticClass:"only-pc",attrs:{"src":"https://www.youtube.com/embed/eAKe4TLdl74?autoplay=1&mute=1&loop=1&playlist=eAKe4TLdl74&showinfo=0&controls=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}}),_c('iframe',{staticClass:"only-mov",attrs:{"src":"https://www.youtube.com/embed/AEINCuX4LKU?autoplay=1&mute=1&loop=1&playlist=AEINCuX4LKU&showinfo=0&controls=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pallete"},[_c('h2',[_vm._v("Color Palette")]),_c('div',[_c('p',[_vm._v("BLUE | PRINCIPAL")]),_c('h3',[_vm._v("#262FFD")])]),_c('div',[_c('p',[_vm._v("BLUE 2 | SECUNDARIO")]),_c('h3',[_vm._v("#06C5FF")])]),_c('div',[_c('p',[_vm._v("GRAY | TEXTO")]),_c('h3',[_vm._v("#676467")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico__video"},[_c('picture',{staticClass:"only-pc aspecRa"},[_c('source',{attrs:{"srcset":require("../../assets/landingDesarrolloPortafolio/davis2.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/landingDesarrolloPortafolio/davis2.png"),"alt":"Imagen de Davis Ind Group","loading":"lazy"}})]),_c('picture',{staticClass:"only-mov"},[_c('source',{attrs:{"srcset":require("../../assets/landingDesarrolloPortafolio/davis2.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/landingDesarrolloPortafolio/davis2.png"),"alt":"Imagen de Davis Ind Group","loading":"lazy"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico"},[_c('div',{staticClass:"video__uno"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/landingDesarrolloPortafolio/davis3.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/landingDesarrolloPortafolio/davis3.png"),"alt":"Imagen de Davis Ind Group","loading":"lazy"}})])]),_c('div',{staticClass:"mosaico__stack"},[_c('picture',{staticClass:"only-pc"},[_c('source',{attrs:{"srcset":require("../../assets/landingDesarrolloPortafolio/davis4.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/landingDesarrolloPortafolio/davis4.png"),"alt":"Imagen de Davis Ind Group","loading":"lazy"}})]),_c('picture',{staticClass:"only-mov"},[_c('source',{attrs:{"srcset":require("../../assets/landingDesarrolloPortafolio/davismv1.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/landingDesarrolloPortafolio/davismv1.png"),"alt":"Imagen de Davis Ind Group","loading":"lazy"}})])])])
}]

export { render, staticRenderFns }